import React, { createElement } from 'react'
// import { PageProps } from 'gatsby'
// import { WithIntlProps } from 'gatsby-plugin-intl'
import 'react-notifications/lib/notifications.css'

import IndexLayout from '../layouts'
import Menu from '../containers/Menu'
import Footer from '../containers/Footer'
import homepageStructure from '../homepageStructure'


// const IndexPage: React.FC<WithIntlProps<PageProps>> = ({ pathContext: { intl: { originalPath } } }) => (
const IndexPage = ({ pathContext: { intl: { originalPath } } }) => (
  <IndexLayout>
    <Menu originalPath={originalPath} />
    {
      homepageStructure.map(({ hash, element, wrapper }) => (
        createElement(
          wrapper || 'section',
          {
            key: hash,
            id: hash,
          },
          element
        )
      ))
    }
    <Footer originalPath={originalPath} />
  </IndexLayout>
)

export default IndexPage
